import React from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Button from 'components/ui-components-v2/Button';
import CommentsDrawer from 'components/social/CommentsDrawer';
import EditorData from 'components/editor-data/EditorData';
import { EmailEditorResonsiveSwitch } from '../../EmailEditor';
import '../styles/multi-email-builder-detail.scss';

/**
 * SHow the detail view of the active email.
 * @param {*} param0
 * @returns
 */
const MultiEmailBuilderDetail = ({
    name,
    description,
    activeEmailId,
    onSetEditorOpen,
    view,
    review,
    mobilePreview,
    hasConditionsInterface,
    canEdit,
    onSetView,
    onSetMobilePreview,
    onSetRemoveDialogOpen,
    onCopyEmail,
    children
}) => {
    return (
        <div className="multi-email-builder-detail">
            <div className="multi-email-builder-detail__header">
                <div className="multi-email-builder-detail__header__left">
                    <div className="multi-email-builder-detail__header__icon">
                        <Icon>email</Icon>
                    </div>
                    <div>
                        <div className="multi-email-builder-detail__header__title">
                            <div className="multi-email-builder-detail__header__title__copy">{name}</div>
                        </div>
                        {description && description.length && <div className="multi-email-builder-detail__header__subtitle">{description}</div>}
                    </div>
                </div>
                <div className="multi-email-builder-detail__header__right">
                    {canEdit && (
                        <Button color="primary" variant="contained" size="small" onClick={() => onSetEditorOpen(true)}>
                            Edit design
                        </Button>
                    )}
                    {(canEdit || review) && (
                        <div className="multi-email-builder-detail__header__actions">
                            {canEdit && (
                                <Tooltip title="Duplicate this email">
                                    <IconButton onClick={() => onCopyEmail()} size="small">
                                        <Icon>content_copy</Icon>
                                    </IconButton>
                                </Tooltip>
                            )}
                            {review && (
                                <CommentsDrawer
                                    key={`multiEmailBuilder.${activeEmailId}`}
                                    entity={`multiEmailBuilder.${activeEmailId}`}
                                    campaignId={EditorData.getId()}
                                />
                            )}
                            {canEdit && (
                                <Tooltip title="Remove this email">
                                    <IconButton onClick={() => onSetRemoveDialogOpen(true)} size="small">
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="multi-email-builder-detail__nav">
                <div className="multi-email-builder-detail__tabs">
                    <div
                        className={classNames('multi-email-builder-detail__tabs__tab', {
                            'multi-email-builder-detail__tabs__tab--active': view === 'design'
                        })}
                        onClick={() => onSetView('design')}>
                        Design
                    </div>
                    {canEdit && (
                        <React.Fragment>
                            <div
                                className={classNames('multi-email-builder-detail__tabs__tab', {
                                    'multi-email-builder-detail__tabs__tab--active': view === 'settings'
                                })}
                                onClick={() => onSetView('settings')}>
                                Settings
                            </div>
                            {hasConditionsInterface && (
                                <div
                                    className={classNames('multi-email-builder-detail__tabs__tab', {
                                        'multi-email-builder-detail__tabs__tab--active': view === 'conditions'
                                    })}
                                    onClick={() => onSetView('conditions')}>
                                    Conditions
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>
                <div>{view === 'design' && <EmailEditorResonsiveSwitch mobilePreview={mobilePreview} onSetMobile={onSetMobilePreview} />}</div>
            </div>
            <div>{children && children}</div>
        </div>
    );
};

export default MultiEmailBuilderDetail;
