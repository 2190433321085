import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { arrayMoveImmutable } from 'array-move';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import User from 'components/data/User';
import Setup from 'components/data/Setup';
import EmailHelpers from 'components/data/EmailHelpers';
import Templates, { withTemplates } from 'components/data/Templates';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';
import EditorData from 'components/editor-data/EditorData';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import TemplateDialog from 'components/ui-base/TemplateDialog';
import VisualStackEditor from 'components/ui-base/VisualStackEditor';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import DataHelpers from 'components/data/DataHelpers';
import { isAMV2Enabled } from 'components/template-management/utilities';
import AssetGalleryDialog from 'components/assets/AssetGalleryDialog';
import { convertAssetV2toTemplate } from 'components/data/Templates/helpers/template-converter';
import Translation from 'components/data/Translation';
import { exportVisualStackBlockToCreativeManagement } from 'components/creative-management-v2/utilities';
import EmailEditorResonsiveSwitch from './responsive-switch';
import EmailEditorContentArea from './content-area';

import '../styles/main.scss';

/**
 * EmailEditor
 * This is the editor module for changing emails.
 * It allows adding, removing, editing blocks, and setting up all data.
 * It displays a preview of the email block as well.
 */
class EmailEditor extends Component {
    static defaultProps = {
        canEdit: true, // Global permission defined in campaignFormat
        canMove: true, // Global permission defined in campaignFormat
        canRemove: true, // Global permission defined in campaignFormat
        canAdd: true, // Global permission defined in campaignFormat
        canEditAB: true, // Global permission defined in campaignFormat
        settingsModel: 'email.settings'
    };

    constructor(props) {
        super(props);

        const { settingsModel, dataModel } = props;

        // Get AB testing setting.
        const abTesting = VisualStackEditorHelpers.getAbTestSetting(settingsModel);

        const userLevel = parseInt(User.getLevel());

        // Remove blacks that can't be tied to a template from the setup.
        const value = VisualStackEditorHelpers.cleanUpValue(props.value, Templates.get('emailBlock'));

        // Check AB testing on load
        VisualStackEditorHelpers.checkAbTesting(value, abTesting, dataModel);

        // Set editingItem on first block if present;
        const editingItem = VisualStackEditorHelpers.getEditingItem(value[0]?.uuid, value, Templates.get('emailBlock'));

        this.state = {
            value,
            userLevel,
            isAddBlockDialogOpen: false,
            addingToIndex: false,
            componentIdPendingForRemoval: false,
            sourceBlockUuid: false,
            targetBlockUuid: -1,
            editingItem,
            abTesting,
            abTestGroupsShown: abTesting ? [...abTesting] : false,
            ...this.setPermissions(editingItem && editingItem.uuid, value, userLevel),
            ...this.setupAddOverlay(userLevel),
            emailBase: EmailHelpers.getEmailBase(props.groupKey),
            mobilePreview: false
        };
    }

    static mapStateToProps(state) {
        return {
            editor: state.editor
        };
    }

    /**
     * Setup the options of an overlay with email template blocks to add to the email.
     * Email template blocks are filtered on userlevel.
     * @param {number} userLevel Userlevel of the current logedin user.
     * @returns {object} addOptions contains addable template blocks. addCategories containes categories for these template blocks.
     */
    setupAddOverlay = (userLevel) => {
        if (isAMV2Enabled()) {
            const dataFilters = {};
            const selectors = ['template'];
            dataFilters['settings.levelCanAdd'] = `<=${User.getLevel()}`;

            const { groupKey } = this.props;

            if (groupKey) dataFilters.groupKey = groupKey;

            const templateProps = {
                subType: ['emailBlock'],
                dataFilters,
                onSelectItem: this.handleAddItem
            };

            const creativeProps = (() => {
                if (!isAMV2Enabled('creative')) return null;
                if (!Setup.hasModule('creativeManagement')) return null;
                selectors.push('creative');
                {
                    return {
                        subType: ['emailBlock'],
                        dataFilters,
                        onSelectItem: this.handleAddCreative
                    };
                }
            })();

            return {
                templateProps,
                creativeProps,
                selectors
            };
        }
        const emailBlocks = Templates.get('emailBlock');
        const addOptions = (() => {
            if (!emailBlocks) {
                return {};
            }
            return Object.keys(emailBlocks)
                .map((key) => emailBlocks[key])
                .filter((item) => item.groupKey === this.props.groupKey || !this.props.groupKey)
                .filter((item) => {
                    const settings = item.settings ? item.settings : {};
                    if (!('levelCanAdd' in settings)) {
                        return true;
                    } else if (settings.levelCanAdd <= userLevel) {
                        return true;
                    } else {
                        return false;
                    }
                });
        })();

        const addCategories = [];
        if (emailBlocks) {
            Object.keys(emailBlocks).forEach((key) => {
                const thisCat = emailBlocks[key].category ? emailBlocks[key].category.toLowerCase() : null;
                if (thisCat && thisCat.length > 0 && addCategories.indexOf(thisCat) < 0) {
                    addCategories.push(thisCat);
                }
            });
        }

        return {
            addOptions: addOptions,
            addCategories: addCategories
        };
    };

    setBaseHtml = () => {
        const { emailBases, groupKey } = this.props;

        // Get base
        let emailBase = {};
        Object.keys(emailBases).forEach((key) => {
            const item = emailBases[key];
            if (!groupKey || (item.groupKey && item.groupKey === groupKey)) {
                emailBase = item;
            }
        });

        return {
            emailBase
        };
    };

    /**
     * Start dragging action of a email block
     * @param {string} sourceUuid id of the block being dragged.
     */
    handleStartDragBlock = (sourceUuid) => {
        const { value, userLevel } = this.state;
        const emailBlocks = Templates.get('emailBlock');

        this.setState(
            {
                ...this.setPermissions(sourceUuid, value, userLevel),
                sourceBlockUuid: sourceUuid,
                editingItem: VisualStackEditorHelpers.getEditingItem(sourceUuid, value, emailBlocks)
            },
            () => this.moveToolbox()
        );
    };

    /**
     * Called when hovering over a block during a drag action.
     * @param {string} targetUuid id of the block being hovered over
     * @param {object} clientOffset last recorded { x, y } client offset of the pointer while a drag operation is in progress.
     */
    handleHoverBlock = (targetUuid, clientOffset) => {
        const { value, sourceBlockUuid } = this.state;
        const sourceIndex = value.findIndex((x) => x.uuid === sourceBlockUuid);
        const targetIndex = value.findIndex((x) => x.uuid === targetUuid);

        if (sourceIndex === targetIndex) {
            return;
        }

        const hoverBoundingRect = document.getElementById(targetUuid).getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Draging downwards
        if (sourceIndex < targetIndex && hoverClientY < hoverMiddleY) {
            return;
        }
        // Dragging upwards
        if (sourceIndex > targetIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        this.setState(
            {
                targetBlockUuid: targetUuid
            },
            () => this.moveBlock()
        );
    };

    /**
     * Dragging of email block has ended. Update the value with the current order.
     */
    handleEndDragBlock = () => {
        this.setState(
            {
                sourceBlockUuid: false
            },
            () => this.updateModel()
        );
    };

    /**
     * Return an updated order of the email blocks as stored in value.
     * Make sure pinnedToTop blocks are always on top and pinnedToBottom are last.
     * @param {array} value email blocks in their current order.
     * @param {string} sourceIndex id of the email block being dragged.
     * @param {string} targetIndex id of the email block being dragged over.
     */
    setValueOrder = (value, sourceIndex, targetIndex) => {
        const { sourceData } = this.props;

        /**
         * Get a property from a block considering the active subset.
         * @param {object} block
         * @param {string} property
         * @returns {*}
         */
        const getSubsetProperty = (block, property) => {
            const subsetData = DataHelpers.getValue(block, 'subsetData');
            const subsetActive = DataHelpers.getValue(block, 'subsetActive') || 'main';

            if (subsetData) {
                return DataHelpers.getValue(block, `subsetData.${subsetActive}.${property}`);
            } else {
                return DataHelpers.getValue(block, property);
            }
        };

        const newValue = arrayMoveImmutable(value, sourceIndex, targetIndex);

        const pinnedToTop = newValue.filter((b) => sourceData[b.uuid] && getSubsetProperty(sourceData[b.uuid], 'pinnedToTop'));
        const pinnedToBottom = newValue.filter((b) => sourceData[b.uuid] && getSubsetProperty(sourceData[b.uuid], 'pinnedToBottom'));
        const other = newValue.filter(
            (b) => !sourceData[b.uuid] || (!getSubsetProperty(sourceData[b.uuid], 'pinnedToTop') && !getSubsetProperty(sourceData[b.uuid], 'pinnedToBottom'))
        );

        const sortedValue = [...pinnedToTop, ...other, ...pinnedToBottom];

        return sortedValue;
    };

    /**
     * Keep the email block on index 0 as stored in value on index 0
     */
    handleSortBlocks = () => {
        const { value } = this.state;
        const newValue = this.setValueOrder(value, 0, 0);
        this.setState(
            {
                value: newValue
            },
            () => this.updateModel()
        );
    };

    /**
     * Set the updated order of the email blocks as stored in value, and trigger the function to move the toolbox.
     * value: email blocks in their current order.
     * sourceIndex: index of the email block being dragged.
     * targetIndex: id of the email block being dragged over.
     */
    moveBlock = () => {
        const { sourceBlockUuid, targetBlockUuid, value } = this.state;
        const sourceIndex = value.findIndex((x) => x.uuid === sourceBlockUuid);
        const targetIndex = value.findIndex((x) => x.uuid === targetBlockUuid);

        const newValue = this.setValueOrder(value, sourceIndex, targetIndex);

        this.setState(
            {
                value: newValue,
                editingItem: VisualStackEditorHelpers.getEditingItem(sourceBlockUuid, newValue, Templates.get('emailBlock'))
            },
            () => this.updateModel()
        );
    };

    /**
     * Change the order of the email blocks using the up and down keys of the toolbox.
     * @param {number} offset change in position in the value array of the active block.
     */
    handleMoveBlock = (offset) => {
        const { value, editingItem } = this.state;
        const activeBlock = editingItem && editingItem.uuid;
        const currentIndex = value.findIndex((x) => x.uuid === activeBlock);
        const newIndex = currentIndex + offset;

        if (newIndex >= 0 && newIndex <= value.length - 1) {
            const newArray = this.setValueOrder(
                value,
                value.findIndex((x) => x.uuid === activeBlock),
                currentIndex + offset
            );
            this.setState(
                {
                    value: newArray
                },
                () => this.updateModel()
            );
        }
    };
    /**
     * Align the y-position of the toolbox with the active email block.
     */
    moveToolbox = () => {
        const { editingItem } = this.state;
        if (editingItem && editingItem.uuid && document.getElementById(editingItem.uuid)) {
            const contentBox = document.getElementById('email-editor-content-area').getBoundingClientRect();
            const blockBox = document.getElementById(editingItem.uuid).getBoundingClientRect();
            document.getElementById('visual-stack-editor-toolbox').style.top = `${blockBox.top - contentBox.top}px`;
        }
    };

    /**
     * Open the add block dialog and determine at what index to add the new email bock.
     */
    handleOpenAddBlockDialog = () => {
        const { value, editingItem } = this.state;
        const activeBlock = editingItem && editingItem.uuid;
        const addingToIndex = activeBlock ? value.findIndex((block) => block.uuid === activeBlock) : false;

        this.setState({
            isAddBlockDialogOpen: !this.state.isAddBlockDialogOpen,
            addingToIndex: addingToIndex
        });
    };

    /**
     * Close the add block dialog.
     */
    handleCloseAddBlockDialog = () => {
        this.setState({ isAddBlockDialogOpen: false });
    };

    /**
     * Get the new value when a blcok is added.
     */
    getNewValue = (uuid, blockItem, blockData) => {
        const { value, addingToIndex } = this.state;
        const newArray = [...value];
        if (newArray.findIndex((b) => b.uuid === uuid) < 0) {
            if (blockData.pinnedToTop) {
                newArray.unshift(blockItem);
            } else if (blockData.pinnedToBottom) {
                newArray.push(blockItem);
            } else if (addingToIndex !== false) {
                newArray.splice(addingToIndex + 1, 0, blockItem);
            } else {
                newArray.push(blockItem);
            }
        }
        const newValue = this.setValueOrder(newArray, 0, 0);
        return newValue;
    };

    /**
     * Get the start data for a new email block.
     * @param {object} blockData
     * @returns
     */
    getStartData = (blockData) => {
        const { abTesting } = this.state;
        const { subsets } = this.props;

        const startData = (() => {
            const startData = {
                abTestGroups: abTesting && abTesting.join()
            };

            if (subsets) {
                return {
                    subsetData: {
                        main: blockData
                    }
                };
            } else {
                return {
                    ...startData,
                    ...blockData
                };
            }
        })();

        return startData;
    };

    /**
     * Add a new email block based on a template block.
     * @param {object} item the template block the new email block is based on.
     */
    handleAddItem = (item) => {
        if (isAMV2Enabled()) {
            item = convertAssetV2toTemplate(item);
        }

        if (this.maxReached(item)) {
            // Check if the maximum use of this blocktype is reached.
            SnackbarUtils.warning(Translation.get('visualStackEditor.maxBlocksOfTypeReached', 'editor'));
            return;
        }

        const { userLevel } = this.state;
        const { dataModel } = this.props;
        const emailBlocks = Templates.get('emailBlock');
        if (!emailBlocks[item.identifier]) return;
        const uuid = 'block' + uuidv4().replace(/-/g, '');
        const blockData = cloneDeep(emailBlocks[item.identifier].defaultData) || {};
        const newBlock = {
            uuid,
            identifier: item.identifier
        };

        const newValue = this.getNewValue(uuid, newBlock, blockData);

        // Apply default data
        try {
            const startData = this.getStartData(blockData);
            EditorData.setModel(dataModel + '.' + uuid, startData);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Editor error', e);
        }

        this.setState(
            {
                ...this.setPermissions(uuid, newValue, userLevel),
                value: newValue,
                isAddBlockDialogOpen: false,
                addingToIndex: false,
                editingItem: VisualStackEditorHelpers.getEditingItem(uuid, newValue, emailBlocks)
            },
            () => this.updateModel()
        );
    };

    handleAddCreative = (item) => {
        if (this.maxReached(item.data)) {
            // Check if the maximum use of this blocktype is reached.
            SnackbarUtils.warning(Translation.get('visualStackEditor.maxBlocksOfTypeReached', 'editor'));
            return;
        }
        const { userLevel } = this.state;
        const { dataModel } = this.props;
        const emailBlocks = Templates.get('emailBlock');
        if (!emailBlocks[item.data.templateIdentifier]) return;
        const { setup, blocks } = item.data.templateInput;
        const storedUuid = setup[0].uuid;
        const uuid = 'block' + uuidv4().replace(/-/g, '');
        const blockData = blocks[storedUuid] ? cloneDeep(blocks[storedUuid]) : {};
        const newBlock = {
            uuid,
            identifier: item.data.templateIdentifier
        };
        const newValue = this.getNewValue(uuid, newBlock, blockData);

        // Apply the templateInput
        try {
            const startData = this.getStartData(blockData);
            EditorData.setModel(dataModel + '.' + uuid, startData);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Editor error', e);
        }

        this.setState(
            {
                ...this.setPermissions(uuid, newValue, userLevel),
                value: newValue,
                isAddBlockDialogOpen: false,
                addingToIndex: false,
                editingItem: VisualStackEditorHelpers.getEditingItem(uuid, newValue, emailBlocks)
            },
            () => this.updateModel()
        );
    };

    /**
     * Set a email block as active, the block being edited.
     * Set permissions based on the new active block.
     * Position the toolbox based on the new active block.
     * @param {string} uuid id of the new active email block.
     */
    handleSetActive = (uuid, scroll) => {
        const { value, userLevel } = this.state;

        this.setState(
            {
                editingItem: VisualStackEditorHelpers.getEditingItem(uuid, value, Templates.get('emailBlock')),
                mobilePreview: false,
                ...this.setPermissions(uuid, value, userLevel)
            },
            () => {
                if (uuid) {
                    if (scroll && document.getElementById(uuid)) document.getElementById(uuid).scrollIntoView();
                    this.moveToolbox();
                }
            }
        );
    };

    /**
     * Determine the permissions of a email block.
     * @param {string} uuid id of the email block
     * @param {array} value configured email
     * @param {number} userLevel level of the current user
     * @param {object} emailBlocks email template blocks
     * @returns {object} The returned object contains boolean keys canMove, canRemove, canEdit and canAdd
     */
    setPermissions = (uuid, value, userLevel) => {
        const { dataModel, canMove, canRemove, canEdit, canAdd, maxBlocks, subsets } = this.props;
        const emailBlocks = Templates.get('emailBlock');

        // Check whether maximum number of blocks is reached based on maxBlocks key in campaignFormat
        const maxBlocksReached = maxBlocks && value.length >= maxBlocks;

        if (uuid) {
            // Rights on global level come from campaignFormat via props.
            let canMoveBlock = canMove;
            let canRemoveBlock = canRemove;
            let canEditBlock = canEdit;

            // Get block settings
            const thisValue = value.find((v) => {
                return v.uuid === uuid;
            });
            const settings = emailBlocks[thisValue.identifier].settings ? emailBlocks[thisValue.identifier].settings : {};

            // Determine rights on block settings / template level
            canMoveBlock = this.getPermission('levelCanMove', settings, userLevel, canMoveBlock);
            canRemoveBlock = this.getPermission('levelCanRemove', settings, userLevel, canRemoveBlock);
            canEditBlock = this.getPermission('levelCanEdit', settings, userLevel, canEditBlock);

            // To Determine rights on block content level, first get the data of that block.
            let activeBlockData = EditorData.getValueFromModel(dataModel + '.' + uuid);

            // See if there is an active subset. In that case use the data from that subset a activeBlock.
            if (subsets && activeBlockData?.subsetData && activeBlockData?.subsetActive) {
                activeBlockData = EditorData.getValueFromModel(dataModel + '.' + uuid + '.subsetData.' + activeBlockData.subsetActive);
            }

            if (activeBlockData && activeBlockData?.locked) {
                canRemoveBlock = false;
            }
            if (activeBlockData && activeBlockData?.pinnedToTop) {
                canMoveBlock = false;
            }
            if (activeBlockData && activeBlockData?.pinnedToBottom) {
                canMoveBlock = false;
            }

            // Return derived permissions object to be used in component state.
            return {
                canMove: canMoveBlock,
                canRemove: canRemoveBlock,
                canEdit: canEditBlock,
                canAdd: canAdd && !maxBlocksReached // Can add is always a global setting provided maxBlocks isn't reached.
            };
        } else {
            return {
                canMove: false,
                canRemove: false,
                canEdit: false,
                canAdd: canAdd && !maxBlocksReached // Can add is always a global setting provided maxBlocks isn't reached.
            };
        }
    };

    /**
     * Get the permission for one feature of a email block based on the settings of the template block.
     * @param {string} feature The feauture being checked.
     * @param {object} settings The settings as set in the template block.
     * @param {number} userLevel level of the current user.
     * @param {boolean} orgSetting The start setting for the feature being examened before this function is called.
     * @returns {boolean}
     */
    getPermission = (feature, settings, userLevel, orgSetting) => {
        if (!(feature in settings)) {
            return orgSetting;
        } else if (settings[feature] <= userLevel) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Update the permissions as stored in the state, based on the current active email block.
     */
    updatePermissions = () => {
        const { editingItem, value, userLevel } = this.state;
        this.setState({
            ...this.setPermissions(editingItem && editingItem.uuid, value, userLevel)
        });
    };

    /**
     * Check if maximum use of this block template type is set and if it is reached.
     * @param {object} item template block.
     * @returns {boolean} true if the maximum of this template block type is reached.
     */
    maxReached = (item) => {
        const { value } = this.state;
        if (item.settings && item.settings.maxUse) {
            const nowUse = value.filter((check) => {
                return check.identifier === item.identifier;
            }).length;

            if (nowUse >= item.settings.maxUse) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    /**
     * Removes a email block, sets a new active block and apply permissions based on the new situation.
     * @param {string} uuid id of the email block to remove.
     */
    removeItem = (uuid) => {
        const { dataModel } = this.props;
        const { value, userLevel } = this.state;
        const newValue = value.filter((x) => x.uuid !== uuid);
        const newUuid = newValue.length > 0 ? newValue[0].uuid : false;
        const emailBlocks = Templates.get('emailBlock');

        this.setState(
            {
                ...this.setPermissions(newUuid, newValue, userLevel),
                value: newValue,
                editingItem: VisualStackEditorHelpers.getEditingItem(newUuid, newValue, emailBlocks)
            },
            () => {
                EditorData.setModel(dataModel + '.' + uuid, {});
                if (newUuid) {
                    this.moveToolbox();
                }
                this.updateModel();
            }
        );
    };

    /**
     * Get the editingItem object for a uuid
     * @param {string} uuid unique block identifier
     * @param {string} [identifier] block type identifier
     * @returns editingItem object
     */
    getEditingItem = (uuid, identifier) => {
        if (uuid) {
            const { value } = this.state;
            const emailBlocks = Templates.get('emailBlock');
            const thisIdentifier = identifier ? identifier : value.find((x) => x.uuid === uuid).identifier;

            return {
                identifier: identifier ? identifier : thisIdentifier,
                title: emailBlocks[thisIdentifier].title,
                uuid: uuid,
                interfaceSetup: emailBlocks[thisIdentifier].interfaceSetup
            };
        }
        return null;
    };

    /**
     * Set the active block pending for removal.
     */
    handleDelete = () => {
        const { editingItem } = this.state;
        this.setState({
            componentIdPendingForRemoval: editingItem && editingItem.uuid
        });
    };

    /**
     * Cancel the emial block pending removal.
     */
    handleDeleteCancelled = () => {
        this.setState({
            componentIdPendingForRemoval: false
        });
    };

    /**
     * Trigger the removal of the block that was pending.
     */
    handleDeleteConfirmed = () => {
        const { componentIdPendingForRemoval } = this.state;
        this.removeItem(componentIdPendingForRemoval);
        this.setState({
            componentIdPendingForRemoval: false
        });
    };

    /**
     * Copy the current active block
     */
    handleCopy = () => {
        const { dataModel } = this.props;
        const { value, editingItem } = this.state;
        if (editingItem) {
            const newValue = [];
            let identifier = '';
            const newUuid = 'block' + uuidv4().replace(/-/g, '');

            // Insert item
            for (const i in value) {
                newValue.push(value[i]);
                if (value[i].uuid === editingItem.uuid) {
                    identifier = value[i].identifier;
                    newValue.push({
                        uuid: newUuid,
                        identifier
                    });
                }
            }

            // Copy data
            const newData = cloneDeep(EditorData.getValueFromModel(dataModel + '.' + editingItem.uuid));
            if (newData.subsetData && Object.keys(newData.subsetData) && Object.keys(newData.subsetData).length > 1) {
                // Replace the subset keys with new unique ones and set the active subset accordingly.
                Object.keys(newData.subsetData).forEach((key) => {
                    if (key !== 'main') {
                        const orgSubset = cloneDeep(newData.subsetData[key]);
                        const newSubsetKey = 's_' + uuidv4().replace(/-/g, '');
                        delete newData.subsetData[key];
                        if (key === newData.subsetActive) {
                            newData.subsetActive = newSubsetKey;
                        }
                        newData.subsetData[newSubsetKey] = orgSubset;
                    }
                });
            }
            EditorData.setModel(dataModel + '.' + newUuid, newData);

            // Save to state
            this.setState(
                {
                    value: newValue,
                    editingItem: VisualStackEditorHelpers.getEditingItem(newUuid, newValue, Templates.get('emailBlock'))
                },
                () => this.updateModel()
            );
        }
    };

    handleExportToCreativeManagement = async () => {
        const { editingItem } = this.state;
        const { dataModel, subsets, groupKey } = this.props;
        await exportVisualStackBlockToCreativeManagement('emailBlock', dataModel, editingItem, subsets, groupKey);
    };

    /**
     * Colse the editing interface.
     */
    closeEdit = () => {
        this.setState({
            editingItem: null
        });
    };

    /**
     * Turn mobile view on or off.
     * @param {boolean} value
     */
    setMobilePreview = (value) => {
        this.setState({
            mobilePreview: value,
            editingItem: null
        });
    };

    /**
     * Commit changes made to the the email value to the campaign model in Redux.
     */
    updateModel = () => {
        if (this.timout) {
            clearTimeout(this.timeout);
        }
        setTimeout(() => {
            this.props.onMutation(this.state.value);
        }, 500);
    };

    /**
     * Renders the components
     * @returns {*}
     */
    render() {
        const {
            isAddBlockDialogOpen,
            componentIdPendingForRemoval,
            value,
            sourceBlockUuid,
            editingItem,
            addOptions,
            addCategories,
            canEdit,
            canMove,
            canRemove,
            canAdd,
            abTesting,
            abTestGroupsShown,
            mobilePreview,
            emailBase,
            selectors,
            creativeProps,
            templateProps
        } = this.state;
        const { dataModel, sourceData, editor, originSelector, assetGalleryDialogProps, canEditAB, subsets, onClose } = this.props;

        return (
            <DndProvider backend={HTML5Backend} context={window}>
                <VisualStackEditor
                    templateType="emailBlock"
                    value={value}
                    dataModel={dataModel}
                    editingItem={editingItem}
                    subsets={subsets}
                    language={editor && editor.language}
                    title="Edit email"
                    abTesting={abTesting}
                    abTestGroupsShown={abTestGroupsShown}
                    originSelector={originSelector}
                    canEdit={canEdit}
                    canEditAB={canEditAB}
                    onClose={onClose}
                    onSetAbTestGroupsShown={(value) => this.setState({ abTestGroupsShown: value })}
                    onSetActive={this.handleSetActive}
                    onCloseEdit={this.closeEdit}>
                    {value && value.length > 0 && (
                        <EmailEditorResonsiveSwitch
                            onSetMobile={this.setMobilePreview}
                            mobilePreview={mobilePreview}
                            className="email-editor-content-area__responsive-switch"
                        />
                    )}
                    <EmailEditorContentArea
                        key={'email_' + editor.language + '_' + editor.origin}
                        dataModel={dataModel}
                        sourceData={sourceData}
                        value={value}
                        activeBlock={editingItem && editingItem.uuid}
                        sourceBlockUuid={sourceBlockUuid}
                        canEdit={canEdit}
                        canMove={canMove}
                        canRemove={canRemove}
                        canAdd={canAdd}
                        abTesting={abTesting}
                        abTestGroupsShown={abTestGroupsShown}
                        mobilePreview={mobilePreview}
                        emailBase={emailBase}
                        onHoverBlock={this.handleHoverBlock}
                        onStartDragBlock={this.handleStartDragBlock}
                        onEndDragBlock={this.handleEndDragBlock}
                        onSetActive={this.handleSetActive}
                        onDelete={this.handleDelete}
                        onCopy={this.handleCopy}
                        onExportToCreativeManagement={this.handleExportToCreativeManagement}
                        onMoveBlockKeys={this.handleMoveBlock}
                        onMoveToolbox={this.moveToolbox}
                        onOpenAddBlockDialog={this.handleOpenAddBlockDialog}
                        onUpdatePermissions={this.updatePermissions}
                        onSortBlocks={this.handleSortBlocks}
                    />
                </VisualStackEditor>

                {addOptions && (
                    // This is the old, on ContentSpace based TemplateDialog
                    <TemplateDialog
                        isOpen={isAddBlockDialogOpen}
                        hideCreatives
                        options={addOptions}
                        categories={addCategories}
                        onClose={this.handleCloseAddBlockDialog}
                        onSelectItem={this.handleAddItem}
                    />
                )}

                {templateProps && (
                    // This is the AMV2 based TemplateDialog
                    <AssetGalleryDialog
                        {...assetGalleryDialogProps}
                        title={Translation.get('formflow.JSON.addItem', 'common')}
                        onMutation={assetGalleryDialogProps?.onMutation ?? (() => null)}
                        open={isAddBlockDialogOpen}
                        fixedHeightPaperScrollPaper
                        onClose={this.handleCloseAddBlockDialog}
                        selectors={selectors}
                        selectorsProps={{ templateProps, creativeProps }}
                    />
                )}

                <ConfirmDialog
                    open={componentIdPendingForRemoval ? true : false}
                    title="Delete block?"
                    description="Are you sure you want to delete this block (cannot be  undone)"
                    onConfirm={this.handleDeleteConfirmed}
                    onClose={this.handleDeleteCancelled}
                />
            </DndProvider>
        );
    }
}

export default withTemplates(connect(EmailEditor.mapStateToProps, {})(EmailEditor), [
    { type: 'emailBlock', fullData: true },
    { type: 'emailBase', fullData: true }
]);
